@import "@/styles/_variables.scss";













































































































































































































































































































































































































h1 {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--app-primary-color);
}
.custom-card-bg {
  background: var(--app-secondary-color);
}
.uk-card-secondary.uk-card-body h4 {
  color: var(--text-color);
}
